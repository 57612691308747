import React, { useEffect, useState } from "react";
import EventCard from "../components/card/EventCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllEventDatasAction } from "../store/event/actions";
import HeaderComponent from "../model/HeaderComponent";
import NewEventForm from "../components/form/NewEventForm";
import ScrollableFrame from "../layout/ScrollableFrame";

const Event = () => {
  const { auth, event } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getAllEventDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);
  return (
    <div className="mt-6">
      <HeaderComponent
        item="New"
        modelTitle="Add New"
        isNotCollapse={true}
        ModelComponent={<NewEventForm onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
      />
      <ScrollableFrame>
        <div className="flex flex-wrap space-x-4 items-center justify-center space-y-4">
          {event?.all?.data?.map((d) => (
            <EventCard data={d} />
          ))}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default Event;
