import React from "react";
import MessageTable from "../components/tables/MessageTable";

const Messages = () => {
  return (
    <div className="mt-10">
      <MessageTable />
    </div>
  );
};

export default Messages;
