import { myMessageActions } from ".";
import { getAllMessagesDataService, updateMessageservice } from "./services";

export const getAllMessageDatasAction = (token, query) => {
  return async (dispatch) => {
    try {
      dispatch(myMessageActions.setIsFetching(true));
      const res = await getAllMessagesDataService(token, query);
      if (res?.status === 200) {
        dispatch(myMessageActions.setAll(res));
        dispatch(myMessageActions.setIsFetching(false));
      }
      dispatch(myMessageActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateInfMsgAction = (itemId, token, data) => {
  return async (dispatch) => {
    try {
      dispatch(myMessageActions.setIsFetching(true));
      const res = await updateMessageservice(itemId, data, token);
      // console.log("Look response", res);
      if (res?.status === 200) {
        const resAll = await getAllMessagesDataService(token, "?");
        dispatch(myMessageActions.setAll(resAll));
        dispatch(myMessageActions.setUpdated(res));
        dispatch(myMessageActions.setIsFetching(false));
      }
      dispatch(myMessageActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
