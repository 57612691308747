import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllVisitDataService = async (token, query) => {
  return HttpRequest.get(`${SERVER_URL}/visits${query}`, token);
};

export const getOne = async (id, token) => {
  return HttpRequest.get(`${SERVER_URL}/visits/one/${id}`, token);
};

export const updateInfVisitService = async (itemId, data, token) => {
  return await HttpRequest.update(
    `${SERVER_URL}/visits/one/${itemId}`,
    data,
    token
  );
};
