import { myCultureActions } from ".";
import {
  getAllCultureDataService,
  getOne,
  updateInfoCulture,
} from "./services";

export const getAllCultureDatasAction = (token, query) => {
  return async (dispatch) => {
    try {
      dispatch(myCultureActions.setIsFetching(true));
      const res = await getAllCultureDataService(token, query);
      // console.log("***", res);
      if (res?.status === 200) {
        dispatch(myCultureActions.setAll(res));
        dispatch(myCultureActions.setIsFetching(false));
      }
      dispatch(myCultureActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getCultureAction = (itemId, token) => {
  return async (dispatch) => {
    try {
      dispatch(myCultureActions.setIsFetching(true));
      const res = await getOne(itemId, token);
      if (res?.status === 200) {
        dispatch(myCultureActions.setSelected(res?.data));
        dispatch(myCultureActions.setIsFetching(false));
      }
      dispatch(myCultureActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateInfoCultureAction = (itemId, token, data) => {
  return async (dispatch) => {
    try {
      dispatch(myCultureActions.setIsFetching(true));
      const res = await updateInfoCulture(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllCultureDataService(token, "?");
        dispatch(myCultureActions.setAll(resAll));
        dispatch(myCultureActions.setUpdated(res));
        dispatch(myCultureActions.setIsFetching(false));
      }
      dispatch(myCultureActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
