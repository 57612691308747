import { notification } from "antd";
import { myUploadActions } from ".";
import { uploadImgService } from "./services";

export const uploadImageAction = (token, data) => {
  return async (dispatch) => {
    try {
      dispatch(myUploadActions.setIsFetching(true));
      const res = await uploadImgService(token, data);
      if (res?.status === 201) {
        dispatch(myUploadActions.setNew(res));
      }
      dispatch(myUploadActions.setIsFetching(false));
      notification.success({ message: "Added Successfuly" });
    } catch (err) {
      console.log(err);
    }
  };
};
