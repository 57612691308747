import React, { useEffect, useState } from "react";
import { Card, Switch, notification } from "antd";
import DeleteModal from "../../model/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { updateInfoCultureAction } from "../../store/culture/actions";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UpdateModal from "../../model/UpdateModel";
import NewCultureForm from "../form/NewCultureForm";

const ArtsCard = (props) => {
  const { auth, culture } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});
  const [isHovered, setIsHovered] = useState(false);

  const handleOnEdit = (data) => {
    setIsModalOpen(true);
    setDataToupdate(data);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const deleteModal = (itemId) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const deleteCulture = async () => {
    auth?.token &&
      (await updateInfoCultureAction(deleteId, auth?.token, {
        isDelete: "true",
      })(dispatch));
    notification.success({ message: "Removed Succesfully" });
    setVisible(false);
  };

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const handleChagePublicationStatus = async (id, data) => {
    if (auth?.token && id) {
      await updateInfoCultureAction(id, auth.token, {
        isActive: data,
      })(dispatch);
    }
  };

  return (
    <div
      className="hover-container relative"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <div className="w-full sm:max-w-sm">
        <Card
          hoverable
          style={{ maxWidth: 350, maxHeight: 450 }}
          cover={
            <img
              alt="example"
              src={props?.data?.image}
              style={{ width: 350, height: 400, objectFit: "cover" }}
            />
          }
        ></Card>
      </div>
      <div className="absolute bottom-0 flex items-center justify-between">
        <button
          className="hover-button mr-2 px-4 py-1 text-blue-500 rounded"
          onClick={() => handleOnEdit(props?.data)}
        >
          <BorderColorOutlinedIcon />
        </button>
        <button
          className="hover-button mr-2 px-4 py-1 text-red-500 rounded"
          onClick={() => deleteModal(props?.data?._id)}
        >
          <DeleteOutlineOutlinedIcon />
        </button>
        <button className="hover-button mr-2 px-4 py-1 text-gray-900 rounded">
          Publish{" "}
          <Switch
            checked={props?.data?.isActive}
            onChange={(e) => handleChagePublicationStatus(props?.data?._id, e)}
            className="bg-gray-800 text-xl"
            defaultChecked
            disabled={culture?.isFetching}
          />
        </button>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteCulture}
        isLoading={culture.isFetching}
        onCancel={handleCancels}
        itemName="Culture"
      />

      <UpdateModal
        component={
          <NewCultureForm
            dataToUpdate={dataToUpdate}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnCancel={handleOnCancel}
      />
    </div>
  );
};

export default ArtsCard;
