import React from "react";
// import { PaginationControlled } from "../filters/Paginate";
const ScrollableFrame = ({
  children,
  loading,
  limit,
  count,
  total,
  setLimit,
  setPage,
  hidePagination,
}) => {
  return (
    <div className="space-y-1">
      <div className=" p-4 h-[calc(100vh-150px)] overflow-y-auto">
        {children}
      </div>
      {/* <div className="">
        {!hidePagination && (
          <PaginationControlled
            loading={loading}
            setPage={setPage}
            setLimit={setLimit}
            limit={limit}
            total={total}
            count={count}
          />
        )}
      </div> */}
    </div>
  );
};

export default ScrollableFrame;
