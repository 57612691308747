import { myArtActions } from ".";
import { getAllArtDataService, getOne, updateArtservice } from "./services";

export const getAllArtDatasAction = (token, query) => {
  return async (dispatch) => {
    try {
      dispatch(myArtActions.setIsFetching(true));
      const res = await getAllArtDataService(token, query);
      if (res?.status === 200) {
        dispatch(myArtActions.setAll(res));
        dispatch(myArtActions.setIsFetching(false));
      }
      dispatch(myArtActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getArtAction = (itemId, token) => {
  return async (dispatch) => {
    try {
      dispatch(myArtActions.setIsFetching(true));
      const res = await getOne(itemId, token);
      if (res?.status === 200) {
        dispatch(myArtActions.setSelected(res?.data));
        dispatch(myArtActions.setIsFetching(false));
      }
      dispatch(myArtActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateInfArtAction = (itemId, token, data) => {
  return async (dispatch) => {
    try {
      dispatch(myArtActions.setIsFetching(true));
      const res = await updateArtservice(itemId, data, token);
      console.log("Look response", res);
      if (res?.status === 200) {
        const resAll = await getAllArtDataService(token, "?");
        dispatch(myArtActions.setAll(resAll));
        dispatch(myArtActions.setUpdated(res));
        dispatch(myArtActions.setIsFetching(false));
      }
      dispatch(myArtActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
