import React from "react";
import DashboardLayout from "../layout/Layout";

const Library = () => {
  return (
    <DashboardLayout>
      <div className="mt-6">Library</div>
    </DashboardLayout>
  );
};

export default Library;
