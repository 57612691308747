import { ReactComponent as DashboardIcon } from "../icons/dashboard/dashboard_vector_icon.svg";
import { ReactComponent as InventoryIcon } from "../icons/dashboard/inventory_vector_icon.svg";
import { ReactComponent as PurchaseIcon } from "../icons/dashboard/purchase_vector_icon.svg";
import { ReactComponent as CustomersIcon } from "../icons/dashboard/customrs_vector_icon.svg";
import { ReactComponent as ChannelIcon } from "../icons/dashboard/channels_vector_icon.svg";
import { ReactComponent as FinanceIcon } from "../icons/dashboard/finance_vector_icon.svg";

export const listManages = [
  // {
  //   title: "Team",
  //   path: "/team",
  //   icon: <CustomersIcon className="SamIcon" />,
  // },
  {
    title: "Culture",
    path: "/culture",
    icon: <InventoryIcon className="SamIcon" />,
  },
  {
    title: "Event",
    path: "/event",
    icon: <ChannelIcon className="SamIcon" />,
  },
  {
    title: "Visit",
    path: "/visit",
    icon: <PurchaseIcon className="SamIcon" />,
  },
  {
    title: "Arts and Craft",
    path: "/arts",
    icon: <FinanceIcon className="SamIcon" />,
  },
  {
    title: "Booking",
    path: "/booking",
    icon: <DashboardIcon className="SamIcon" />,
  },
  {
    title: "Feedbacks",
    path: "/feedbacks",
    icon: <FinanceIcon className="SamIcon" />,
  },
];
