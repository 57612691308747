import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Box,
  Button,
  MenuItem,
  MenuList,
  Paper,
  Grow,
  ClickAwayListener,
  Popper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const TopNav = (props) => {
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([""]);
  const [selectedNav, setSelectedNav] = useState(0);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          <Box
            sx={{ display: { xs: "none", md: "flex" } }}
            className="capitalize"
          >
            {props?.pages?.map((page, index) => {
              if (page?.skip) return null;
              return (
                <Button
                  key={index}
                  sx={{
                    color: selectedNav === index ? "black" : "#89899c",
                    minWidth: 100,
                  }}
                  onClick={(event) => {
                    setSelectedNav(index);
                    navigate(page?.path);
                    page?.sub && handleToggle();
                    page?.sub && setMenuList(page?.sub);
                  }}
                  ref={page?.sub && anchorRef}
                  endIcon={page?.sub && <KeyboardArrowDownIcon />}
                >
                  {page?.caption}
                </Button>
              );
            })}
          </Box>
        </Stack>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            // style={{
            //   transformOrigin:
            //     placement === "bottom-start" ? "left top" : "left bottom",
            // }}
          >
            <Paper sx={{ zIndex: 90, background: "white" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {menuList.map((m) => {
                    if (m?.skip === true) return null;
                    return (
                      <MenuItem
                        onClick={(event) => {
                          // dispatch(authActions.setPathUrl(m.bread)); // @ben, what does this do? 🙄
                          handleClose(event);
                          navigate(m?.path);
                        }}
                      >
                        {m.caption}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default TopNav;
