import React, { useEffect, useState } from "react";
import CultureCard from "../components/card/CultureCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllCultureDatasAction } from "../store/culture/actions";
import HeaderComponent from "../model/HeaderComponent";
import NewCultureForm from "../components/form/NewCultureForm";
import ScrollableFrame from "../layout/ScrollableFrame";

const Culture = () => {
  const { auth, culture } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    getAllCultureDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // console.log(":::::", culture?.all?.data);
  return (
    <div className="mt-6">
      <HeaderComponent
        item="New"
        modelTitle="Add New"
        isNotCollapse={true}
        ModelComponent={<NewCultureForm onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
      />
      <ScrollableFrame>
        <div className="flex flex-wrap space-x-4 items-center justify-center space-y-4">
          {culture?.all?.data?.map((d) => (
            <CultureCard data={d} />
          ))}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default Culture;
