// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { getToken } from "./common";

// // handle the private routes
// function PrivateRoute() {
//   return getToken() ? <Outlet /> : <Navigate to="/login" />;
// }
// export default PrivateRoute;

import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { authActions } from "../store/auth";
import store from "store";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const token = store.get("authToken");
  // console.log("token>>>>>", token);
  const { auth } = useSelector((state) => state);
  useEffect(() => {
    if (token) {
      dispatch(authActions.setToken({ token }));
    }
  }, [auth, dispatch, token]);
  return auth?.token || token ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
