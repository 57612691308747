import React, { useEffect, useState } from "react";
import Card from "../components/card/VisitCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllVisitDatasAction } from "../store/visit/actions";
import HeaderComponent from "../model/HeaderComponent";
import NewVisitForm from "../components/form/NewVisitForm";
import ScrollableFrame from "../layout/ScrollableFrame";

const Visit = () => {
  const { auth, visit } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getAllVisitDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  return (
    <div className="mt-6">
      <HeaderComponent
        item="New"
        modelTitle="Add New"
        isNotCollapse={true}
        ModelComponent={<NewVisitForm onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
      />
      <ScrollableFrame>
        <div className="flex flex-wrap space-x-4 items-center justify-center space-y-4">
          {visit?.all?.data?.map((d) => (
            <Card data={d} />
          ))}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default Visit;
