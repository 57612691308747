import store from "store";
import { authActions } from ".";
import { loginService, profileService } from "./authServices";
import { notification } from "antd";

export const loginAction = (data) => {
  return async (dispatch) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set("authToken", res?.data?.data?.token);
        store.set("user", res?.data?.data?.user);
        dispatch(
          authActions.login({ ...res?.data, status: res?.status, isAuth: true })
        );
        dispatch(authActions.setIsFetching(false));
      } else {
        dispatch(authActions.login({ error: res.data, isAuth: false }));
        dispatch(authActions.login({ error: null }));
        dispatch(authActions.setIsFetching(false));
        notification.error({ message: res?.data?.error });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const profileAction = (token) => {
  return async (dispatch) => {
    try {
      dispatch(authActions.setIsFetching(true));
      const res = await profileService(token);
      // console.log(">>>>>>>:: ", res);
      if (res?.status === 200) {
        dispatch(authActions.setUser(res.data));
        dispatch(authActions.setIsFetching(false));
      }
    } catch (err) {
      // dispatch(authActions.login({ isAuth: false }));
      // dispatch(authActions.setIsFetching(false));
      console.log(err);
    }
  };
};
