import React from "react";
import FeedbackTable from "../components/tables/FeedbackTable";

const Feedback = () => {
  return (
    <div className="mt-10">
      <FeedbackTable />
    </div>
  );
};

export default Feedback;
