import * as React from "react";
import ProfileButton from "./ProfileButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import SideNav from "./SideNav";

const Component = ({ nav, handleFullScreenClick }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const isTablet = useMediaQuery("(max-width: 960px)");
  const handleOpenMenu = () => setOpenMenu(!openMenu);
  return (
    <div className="fixed top-0 w-full z-10">
      <div
        className={`relative w-full px-4 pt-1 h-16 gap-1 pl-[256px] bg-white text-black border`}
      >
        {isTablet && (
          <div className="absolute left-2 top-3">
            <MenuIcon className="text-black" onClick={handleOpenMenu} />
          </div>
        )}
        <div className="flex flex-grow">
          <div className="flex  justify-between items-center space-x-0 z-50 flex-1">
            {nav}
            <ProfileButton handleFullScreenClick={handleFullScreenClick} />
          </div>
        </div>
        <ul
          className={
            !openMenu
              ? "hidden"
              : "absolute bg-white w-full border-b-4 translate-x-3 justify-end left-0 mt-2 font-poppins right-0 z-10 transition-all duration-500 ease-in lg:hidden"
          }
        >
          <SideNav />
        </ul>
      </div>
    </div>
  );
};

export default Component;
