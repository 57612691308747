import React from "react";
import { Stack, Button, IconButton, Divider } from "@mui/material";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Delete from "@mui/icons-material/Delete";

const HeaderComponent = (props) => {
  const navigate = useNavigate();
  const showModal = () => {
    !props.goForward && props?.setIsModalOpen(true);
    props.goForward && navigate(props.goForward);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={0}
        >
          <h1>{props?.title}</h1>
          {props?.filterCard}
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <div className="flex justify-center items-center ">
            {props?.showDeleteButton && (
              <IconButton
                color="primary"
                sx={{ p: 1 }}
                aria-label="directions"
                onClick={props?.handleProductsToDelete}
              >
                <Delete />
              </IconButton>
            )}
          </div>
          {!props?.isNotAddButton && (
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={showModal}
              sx={{ minWidth: 250 }}
            >
              Add {props?.item}
            </Button>
          )}
          {props?.isNotAddButton && props.OtherButton}
        </Stack>
      </div>

      <Modal
        title={props?.modelTitle}
        open={props?.isModalOpen}
        onCancel={props?.handleCancel}
        footer={null}
        className="sm:min-w-full md:min-w-min"
      >
        <div>
          <div className="md:w-[100vh]">{props?.ModelComponent}</div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderComponent;
