import React, { useState } from "react";
import { Card, Switch, notification } from "antd";
import DeleteModal from "../../model/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { updateInfEventAction } from "../../store/event/actions";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import UpdateModal from "../../model/UpdateModel";
import NewEventForm from "../form/NewEventForm";

const ArtsCard = (props) => {
  const { auth, culture, event } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataToUpdate, setDataToupdate] = useState({});

  const handleOnEdit = (data) => {
    setIsModalOpen(true);
    setDataToupdate(data);
  };

  const handleCancels = () => {
    setVisible(false);
  };

  const deleteModal = (itemId) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const deleteEvent = async () => {
    auth?.token &&
      (await updateInfEventAction(
        deleteId,
        {
          isDelete: "true",
        },
        auth?.token
      )(dispatch));
    notification.success({ message: "Removed Succesfully" });
    setVisible(false);
  };

  const handleOnCancel = () => {
    setIsModalOpen(false);
  };

  const handleChagePublicationStatus = async (id, data) => {
    if (auth?.token && id) {
      await updateInfEventAction(
        id,
        {
          isActive: data,
        },
        auth.token
      )(dispatch);
    }
  };

  return (
    <div className="hover-container relative">
      <div className="w-full sm:max-w-sm">
        <Card
          hoverable
          style={{ maxWidth: 350, maxHeight: 450 }}
          cover={
            <img
              alt="example"
              src={props?.data?.image}
              style={{ width: 350, height: 400, objectFit: "cover" }}
            />
          }
        ></Card>
      </div>

      <div className="absolute bottom-0 flex items-center justify-between">
        <button
          className="hover-button mr-2 px-4 py-1 text-blue-500 rounded"
          onClick={() => handleOnEdit(props?.data)}
        >
          <BorderColorOutlinedIcon />
        </button>
        <button
          className="hover-button mr-2 px-4 py-1 text-red-500 rounded"
          onClick={() => deleteModal(props?.data?._id)}
        >
          <DeleteOutlineOutlinedIcon />
        </button>
        <button className="hover-button mr-2 px-4 py-1 text-gray-900 rounded">
          Publish{" "}
          <Switch
            checked={props?.data?.isActive}
            onChange={(e) => handleChagePublicationStatus(props?.data?._id, e)}
            className="bg-gray-800 text-xl"
            defaultChecked
            disabled={event?.isFetching}
          />
        </button>
      </div>
      <DeleteModal
        visible={visible}
        onOk={deleteEvent}
        isLoading={culture.isFetching || event?.isFetching}
        onCancel={handleCancels}
        itemName="Image"
      />

      <UpdateModal
        component={
          <NewEventForm
            dataToUpdate={dataToUpdate}
            action={"update"}
            onCancel={handleOnCancel}
          />
        }
        isModalOpen={isModalOpen}
        handleOnCancel={handleOnCancel}
      />
    </div>
  );
};

export default ArtsCard;
