import { Routes, Route } from "react-router-dom";
import NotFound from "../components/Notfound";
import DashArts from "../pages/Arts";
import DashCulture from "../pages/Culture";
import DashEvent from "../pages/Event";
import DashExhibition from "../pages/Exhibition";
import DashLibrary from "../pages/Library";
import DashUser from "../pages/User";
import DashVisit from "../pages/Visit";
import LoginPage from "../pages/LoginPage";
import PrivateRoute from "../utils/privateRoute";
import ArtsRoute from "./ArtsRoute";
import VisitRoutes from "./VisitRoute";
import CultureRoutes from "./CultureRoute";
import EventRoutes from "./EventRoute";
import TeamRoutes from "./TeamRoute";
import Messages from "../pages/Messages";
import BookingRoute from "./BookingRoute";
import FeedbackRoute from "./FeedbackRoute";
import Feedback from "../pages/Feedback";

const MainRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" exact element={<CultureRoutes />} />
          <Route element={<CultureRoutes />}>
            <Route path="/culture" exact element={<DashCulture />} />
          </Route>
          <Route element={<ArtsRoute />}>
            <Route path="/arts" exact element={<DashArts />} />
          </Route>
          <Route element={<EventRoutes />}>
            <Route path="/event" exact element={<DashEvent />} />
          </Route>
          <Route element={<VisitRoutes />}>
            <Route path="/visit" exact element={<DashVisit />} />
          </Route>
          <Route element={<BookingRoute />}>
            <Route path="/booking" exact element={<Messages />} />
          </Route>
          <Route element={<FeedbackRoute />}>
            <Route path="/feedbacks" exact element={<Feedback />} />
          </Route>
          <Route element={<TeamRoutes />}>
            <Route path="/team" exact element={<DashUser />} />
          </Route>
          <Route path="/exhibition" exact element={<DashExhibition />} />
          <Route path="/library" exact element={<DashLibrary />} />

          <Route path="*" exact element={<NotFound />} />
        </Route>
        <Route path="*" exact element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MainRoute;
