import React, { useEffect, useState } from "react";
import Card from "../components/card/ArtsCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllArtDatasAction } from "../store/arts/actions";
import HeaderComponent from "../model/HeaderComponent";
import NewArtForm from "../components/form/NewArtsForm";
import ScrollableFrame from "../layout/ScrollableFrame";

const Arts = () => {
  const { auth, arts } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getAllArtDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  return (
    <div className="mt-6">
      <HeaderComponent
        item="New"
        modelTitle="Add New"
        isNotCollapse={true}
        ModelComponent={<NewArtForm onCancel={handleCancel} />}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleCancel={handleCancel}
      />
      <ScrollableFrame>
        <div className="flex flex-wrap space-x-4 items-center justify-center space-y-4">
          {arts?.all?.data?.map((d) => (
            <Card data={d} />
          ))}
        </div>
      </ScrollableFrame>
    </div>
  );
};

export default Arts;
