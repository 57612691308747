import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const getAllFeedbacksDataService = async (token, query) => {
  return HttpRequest.get(`${SERVER_URL}/feedback${query}`, token);
};

export const updateFeedbackService = async (itemId, data, token) => {
  return await HttpRequest.update(
    `${SERVER_URL}/feedback/one/${itemId}`,
    data,
    token
  );
};
