import { Button, Popconfirm, Table } from "antd";
import { useEffect, useState } from "react";
import "./msgTable.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFeedbackDatasAction,
  updateInfFeedbackAction,
} from "../../store/feedback/actions";
import deletePopover from "../../model/deletePopover";
import DeleteIcon from "@mui/icons-material/Delete";

const FeedbackTable = () => {
  const { auth, feedback } = useSelector((state) => state);
  const dispatch = useDispatch();

  const dataMapper = [];
  useEffect(() => {
    getAllFeedbackDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  feedback?.all?.data?.forEach((el) => {
    dataMapper.push({
      key: el?._id,
      date: el?.createdAt?.slice(0, 10),
      name: el?.name,
      email: el?.email,
      message: el?.message,
      dispatch: dispatch,
      data: el,
    });
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    Table.EXPAND_COLUMN,
    {
      title: "Messages",
      dataIndex: "message",
      key: "message",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      render: (text, record) => {
        const confirm = () => {
          updateInfFeedbackAction(record?.key, "", {
            isDelete: "true",
          })(record?.dispatch);
        };
        return (
          <Popconfirm
            title="About to remove :)"
            description="Are you sure to Remove this Feedbacks?"
            okText="Yes"
            okButtonProps={{
              loading: feedback?.isFetching,
              className: "bg-blue-500",
            }}
            onConfirm={confirm}
            cancelText="No"
          >
            <DeleteIcon style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div className="table-container">
      <Table
        columns={columns}
        dataSource={dataMapper}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.message}
            </p>
          ),
        }}
      />
    </div>
  );
};
export default FeedbackTable;
