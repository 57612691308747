import { myFeedbackActions } from ".";
import { getAllFeedbacksDataService, updateFeedbackService } from "./services";

export const getAllFeedbackDatasAction = (token, query) => {
  return async (dispatch) => {
    try {
      dispatch(myFeedbackActions.setIsFetching(true));
      const res = await getAllFeedbacksDataService(token, query);
      if (res?.status === 200) {
        dispatch(myFeedbackActions.setAll(res));
        dispatch(myFeedbackActions.setIsFetching(false));
      }
      dispatch(myFeedbackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateInfFeedbackAction = (itemId, token, data) => {
  return async (dispatch) => {
    try {
      dispatch(myFeedbackActions.setIsFetching(true));
      const res = await updateFeedbackService(itemId, data, token);
      // console.log("Look response", res);
      if (res?.status === 200) {
        const resAll = await getAllFeedbacksDataService(token, "?");
        dispatch(myFeedbackActions.setAll(resAll));
        dispatch(myFeedbackActions.setUpdated(res));
        dispatch(myFeedbackActions.setIsFetching(false));
      }
      dispatch(myFeedbackActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
