import { Navigate, Outlet } from "react-router-dom";
import Layout from "../layout/Layout";
import store from "store";
import TopNav from "../components/navitems/TopNavItems";
import { listManages } from "../assets/data/pages";
import React from "react";
import { useSelector } from "react-redux";

const BookingRoute = () => {
  const { auth } = useSelector((state) => state);

  const token = store.get("authToken");
  return auth.token || token ? (
    <Layout
      allowSearch={true}
      nav={
        <TopNav
          nav={listManages.find(
            (item) => item.title.toLowerCase() === "Message".toLowerCase()
          )}
        />
      }
      selectedNav={4}
    >
      <Outlet />
    </Layout>
  ) : (
    <Navigate to="/home" />
  );
};

export default BookingRoute;
