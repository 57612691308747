import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { uploadImageAction } from "../../store/uploadImg/actions";
import ImageUploader from "../uploadImage/Upload";
import { getAllArtDatasAction } from "../../store/arts/actions";
import { useMediaQuery } from "@mui/material";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewArtsForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState([]);
  const { arts, auth } = useSelector((state) => state);

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    auth?.token &&
      !props?.data &&
      (await uploadImageAction(auth?.token, {
        ...values,
        images: imageUrl,
        model: "arts",
      })(dispatch));
    await getAllArtDatasAction(auth?.token, "?")(dispatch);
    // auth?.token &&
    //   props?.data &&
    //   (await updateUserActions(
    //     props?.data?._id,
    //     {
    //       ...values,
    //       picture: images[0],
    //       isNotify: true,
    //       notification: {
    //         action: "Updated user profile",
    //         role: ["admin"],
    //         message: "Updated user profile",
    //         title: "Updated user profile",
    //       },
    //     },
    //     auth?.token
    //   )(dispatch));
    form.resetFields();
    props?.onCancel();
    setImageUrl([]);
  };

  const handleImageUpload = (secureUrl) => {
    console.log("Uploaded image URL:>>>", secureUrl);
    // Handle the secure URL in OtherComponent
  };
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props?.data || {}}
      // style={{ minWidth: 600 }}
      scrollToFirstError
    >
      <div className="flex flex-wrap md:ml-48 mb-2">
        <ImageUploader
          uploadPreset="lhpestsa"
          onUpload={handleImageUpload}
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
        />
      </div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="titles" label="Title">
            <Input />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                minWidth: isSmallScreen ? "30%" : "75%",
                marginBottom: "10px",
              }}
              loading={arts?.isFetching}
            >
              {props.data ? "update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewArtsForm;
