import React, { useState } from "react";
import { Upload, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";

const uploadImageToCloudinary = async (file, uploadPreset) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", uploadPreset);

  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/dbbdmk2zs/image/upload",
      formData
    );

    return response.data.secure_url;
  } catch (error) {
    console.log("Upload failed:", error);
    return null;
  }
};

const ImageUploader = ({ uploadPreset, onUpload, setImageUrl, imageUrl }) => {
  console.log("imageUrl", imageUrl);

  const handleUpload = async (options) => {
    const { file } = options;
    const secureUrl = await uploadImageToCloudinary(file, uploadPreset);

    if (secureUrl) {
      const updatedImageUrls = [...imageUrl];
      updatedImageUrls.push(secureUrl);
      setImageUrl(updatedImageUrls);
      onUpload(updatedImageUrls);
    }
  };

  const handleDelete = (index) => {
    const updatedImageData = [...imageUrl];
    updatedImageData.splice(index, 1);
    setImageUrl(updatedImageData);
    onUpload(updatedImageData);
  };

  return (
    <div>
      <Upload customRequest={handleUpload} multiple loading="false">
        <Button>Select Image</Button>
      </Upload>
      <div className="flex flex-wrap space-x-1">
        {imageUrl.map((imageUrl, index) => (
          <>
            <img src={imageUrl} alt="Uploaded" style={{ maxWidth: "20%" }} />
            <DeleteOutlined
              style={{
                fontSize: "16px",
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => handleDelete(index)}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export default ImageUploader;
