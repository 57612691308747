import React, { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Input, Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { uploadImageAction } from "../../store/uploadImg/actions";
import ImageUploader from "../uploadImage/Upload";
import {
  getAllCultureDatasAction,
  updateInfoCultureAction,
} from "../../store/culture/actions";
import { useMediaQuery } from "@mui/material";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const NewCultureForm = (props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState([]);
  const { culture, auth } = useSelector((state) => state);

  console.log("dataToUpdate", props?.dataToUpdate);

  const onFinish = async (values) => {
    if (props?.dataToUpdate) {
      await updateInfoCultureAction(props?.dataToUpdate?._id, auth?.token, {
        title: values?.title,
      })(dispatch);

      await getAllCultureDatasAction(auth?.token, "?")(dispatch);
    } else {
      auth?.token &&
        (await uploadImageAction(auth?.token, {
          ...values,
          images: imageUrl,
          model: "culture",
        })(dispatch));
      await getAllCultureDatasAction(auth?.token, "?")(dispatch);
    }

    form.resetFields();
    props?.onCancel();
    setImageUrl([]);
  };

  const handleImageUpload = (secureUrl) => {
    console.log("Uploaded image URL:>>>", secureUrl);
    // Handle the secure URL in OtherComponent
  };

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={props?.dataToUpdate || {}}
      // style={{ minWidth: 600 }}
      scrollToFirstError
    >
      {!props?.dataToUpdate && (
        <div className="flex flex-wrap md:ml-48 mb-2">
          <ImageUploader
            uploadPreset="lhpestsa"
            onUpload={handleImageUpload}
            setImageUrl={setImageUrl}
            imageUrl={imageUrl || props?.dataToUpdate?.image}
          />
        </div>
      )}
      <Row gutter={[16, 16]}>
        {props?.dataToUpdate && (
          <Col span={12}>
            <Form.Item name="title" label="Title">
              <Input />
            </Form.Item>
          </Col>
        )}

        <Col span={24}>
          <Form.Item {...tailFormItemLayout}>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{
                minWidth: isSmallScreen ? "30%" : "75%",
                marginBottom: "10px",
              }}
              loading={culture?.isFetching}
            >
              {props?.dataToUpdate ? "update" : "Save"}
            </LoadingButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NewCultureForm;
