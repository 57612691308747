import React, { useEffect, useState } from "react";
import { Card, Switch, notification } from "antd";
import DeleteModal from "../../model/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { updateInfArtAction } from "../../store/arts/actions";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const UsersCard = (props) => {
  const { auth, arts } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [dataId, setDataId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(props?.data?.isActive);
  const [dataToPublish, setDataToPublish] = useState("");

  const handleCancels = () => {
    setVisible(false);
  };

  const handleCloseModel = () => {
    setIsModalOpen(false);
  };

  console.log("deleteId", deleteId);

  const deleteModal = (itemId) => {
    setDeleteId(itemId);
    setVisible(true);
  };

  const deleteArts = async () => {
    auth?.token &&
      (await updateInfArtAction(deleteId, auth?.token, {
        isDelete: "true",
      })(dispatch));
    notification.success({ message: "Removed Succesfully" });
    setVisible(false);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const performAction = () => {
    // Code to perform the desired action
    console.log("Button clicked!");
  };

  const onChange = (checked, data) => {
    setDataToPublish(data);
    setIsPublished(checked);
  };

  useEffect(() => {
    if (auth?.token && dataToPublish) {
      const response = async () => {
        await updateInfArtAction(dataToPublish, auth?.token, {
          isActive: isPublished,
        })(dispatch);
        notification.success({ message: "Well Published" });
      };
      response();
    }
  }, [auth?.token, dataToPublish, dispatch, isPublished]);

  return (
    <div
      className="hover-container relative"
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <div className="w-full sm:max-w-sm">
        <Card
          hoverable
          style={{ maxWidth: 350, maxHeight: 450 }}
          cover={
            <img
              alt="example"
              src={props?.data?.image}
              style={{ width: 350, height: 400, objectFit: "cover" }}
            />
          }
        ></Card>
      </div>
      {isHovered && (
        <div className="absolute bottom-0 flex items-center justify-between">
          <button
            className="hover-button mr-2 px-4 py-1 text-blue-500 rounded"
            onClick={performAction}
          >
            <BorderColorOutlinedIcon />
          </button>
          <button
            className="hover-button mr-2 px-4 py-1 text-red-500 rounded"
            onClick={() => deleteModal(props?.data?._id)}
          >
            <DeleteOutlineOutlinedIcon />
          </button>
          <button
            className="hover-button mr-2 px-4 py-1 text-gray-900 rounded"
            onClick={performAction}
          >
            Publish{" "}
            <Switch
              defaultChecked={isPublished}
              onChange={(value) => onChange(value, props?.data?._id)}
              style={{
                backgroundColor: isPublished ? "blue" : "gray",
              }}
            />
          </button>
        </div>
      )}
      <DeleteModal
        visible={visible}
        onOk={deleteArts}
        isLoading={arts.isFetching}
        onCancel={handleCancels}
        itemName="Image"
      />
    </div>
  );
};

export default UsersCard;
