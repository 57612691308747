import React from "react";
import { Modal } from "antd";

const UpdateModal = (props) => {
  return (
    <Modal
      title={"Update Details"}
      open={props?.isModalOpen}
      onCancel={props?.handleOnCancel}
      footer={null}
      className="min-w-max"
    >
      <div className="">
        <div className="w-[100vh]">{props?.component}</div>
      </div>
    </Modal>
  );
};

export default UpdateModal;
