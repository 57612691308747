import { useRef, useState } from "react";
import Header from "./Header";
import SideNav from "./SideNav";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const Layout = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const ishome = ["/home"].includes(location.pathname);
  const elementRef = useRef < HTMLDivElement > null;

  const handleFullScreenClick = () => {
    if (elementRef.current) {
      elementRef.current.requestFullscreen();
    }
  };

  const isTablet = useMediaQuery("(max-width: 960px)");

  return (
    <div className="bg-[#f5f7fa] p-0">
      <div
        className={` gap-8 w-full min-h-screen ${
          !props?.dashback && "bg-[#f5f7fa]"
        } `}
      >
        {!isTablet && <SideNav selected={props?.selectedNav || 0} />}
        <div className="flex min-h-screen">
          <div className="w-full min-h-screen pt-10">
            <Header
              nav={props?.nav}
              handleFullScreenClick={handleFullScreenClick}
              isHome={ishome}
            />
            <div className={`p-4 h-[calc(100%-64px)] lg:pl-[270px]`}>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
