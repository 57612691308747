import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth";
import cultureReducer from "./culture";
import uploadReducer from "./uploadImg";
import eventReducer from "./event";
import visitReducer from "./visit";
import artsReducer from "./arts";
import messagesReducer from "./message";
import feedbackReducer from "./feedback";

const store = configureStore({
  reducer: {
    auth: authReducer,
    culture: cultureReducer,
    uploadedImg: uploadReducer,
    event: eventReducer,
    visit: visitReducer,
    arts: artsReducer,
    messages: messagesReducer,
    feedback: feedbackReducer,
  },
});

export default store;
