import React from "react";
import DashboardLayout from "../layout/Layout";
import UsersCard from "../components/card/UsersCard";

const User = () => {
  const data = [
    {
      id: 1,
      imge: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
    {
      id: 1,
      imge: "https://cdn.pixabay.com/photo/2023/05/23/15/26/bengal-cat-8012976_640.jpg",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
    {
      id: 1,
      imge: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
    {
      id: 1,
      imge: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
    {
      id: 1,
      imge: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
    {
      id: 1,
      imge: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      title: "jajcshjc jdsckjsd",
      desc: "qwertyuioiuytrtyuio",
    },
  ];
  return (
    <div className="mt-6">
      <div className="flex flex-wrap space-x-4 items-center justify-center space-y-4">
        {data.map((d) => (
          <UsersCard data={d} />
        ))}
      </div>
    </div>
  );
};

export default User;
