import { myEventActions } from ".";
import {
  getAllEventDataService,
  getOne,
  updateInfEventsService,
} from "./services";

export const getAllEventDatasAction = (token, query) => {
  return async (dispatch) => {
    try {
      dispatch(myEventActions.setIsFetching(true));
      const res = await getAllEventDataService(token, query);
      if (res?.status === 200) {
        dispatch(myEventActions.setAll(res));
        dispatch(myEventActions.setIsFetching(false));
      }
      dispatch(myEventActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getEventAction = (itemId, token) => {
  return async (dispatch) => {
    try {
      dispatch(myEventActions.setIsFetching(true));
      const res = await getOne(itemId, token);
      if (res?.status === 200) {
        dispatch(myEventActions.setSelected(res?.data));
        dispatch(myEventActions.setIsFetching(false));
      }
      dispatch(myEventActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateInfEventAction = (itemId, data, token) => {
  return async (dispatch) => {
    try {
      dispatch(myEventActions.setIsFetching(true));
      const res = await updateInfEventsService(itemId, data, token);
      if (res?.status === 200) {
        const resAll = await getAllEventDataService(token, "?");
        dispatch(myEventActions.setAll(resAll));
        dispatch(myEventActions.setUpdated(res));
        dispatch(myEventActions.setIsFetching(false));
      }
      dispatch(myEventActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
