import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { listManages } from "../assets/data/pages";
import { useMediaQuery } from "@mui/material";

const IconMenu = ({ selected, setIsOpen }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(selected);
  const navigate = useNavigate();
  const isTablet = useMediaQuery("(max-width: 960px)");

  return (
    <div
      className={`fixed flex transition-all duration-300 flex-col h-screen justify-center items-center !bg-white border-r shadow z-50 overflow-hidden`}
    >
      {!isTablet && (
        <div className="flex justify-center items-center h-16 w-full bg-[#2943D6] !text-white">
          <Link to="/culture">Ikirenga</Link>
        </div>
      )}
      <div
        className={`flex flex-col flex-none justify-center items-center max-w-full space-y-5  pl-3 pr-3 pt-8 pb-8 left-0 top-0 md:static xlg:translate-x-0 xz-50 duration-300`}
      >
        <>
          {listManages.map((manage, index) => (
            <>
              <MenuItem
                style={{
                  marginBottom: "0.5rem",
                  padding: "0.5rem 1rem",
                  borderTopLeftRadius: "1rem",
                  borderBottomLeftRadius: "1rem",
                  width: "100%",

                  color: selectedTabIndex === index ? "blue" : "black",
                  borderRight: selectedTabIndex === index ? "solid 2px" : "",
                }}
                className="hover:!text-blue-500 hover:!bg-white space-x-5"
                onClick={() => {
                  setSelectedTabIndex(index);
                  navigate(manage.path);
                }}
              >
                <ListItemIcon
                  className={`SideNav ${
                    selectedTabIndex === index
                      ? "text-blue-600"
                      : "text-gray-500"
                  }`}
                >
                  {manage.icon}
                </ListItemIcon>

                <span
                  className={`font-thin font-exo text-xl ${
                    selectedTabIndex === index
                      ? "text-blue-600"
                      : "text-gray-500"
                  }`}
                >
                  {manage?.title}
                </span>
              </MenuItem>
            </>
          ))}
        </>
      </div>
      <div
        className="w-full flex-grow"
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </div>
  );
};

export default IconMenu;
