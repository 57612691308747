import React, { useState } from "react";
import Logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

import styles from "./Home.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { loginAction } from "../store/auth/authActions";

const LoginPage = () => {
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    await loginAction({ email, password })(dispatch);
    navigate("/culture");
  };

  return (
    <div className={styles.container}>
      <div className="w-full font-exo h-full flex items-center justify-center">
        <div className="bg-white py-4 text-black mx-auto flex justify-center items-center flex-1 lg:flex-none lg:w-1/2 flex-col">
          <div className="mx-auto p-4 lg:max-w-xl flex flex-col justify-center space-y-7">
            <img src={Logo} alt="" className="w-24 h-20" />
            <section>
              <div className="font-doublebold text-4xl">
                Hello Ikirenga heroes, 👋🏿
              </div>
              <div className="text-gray-400 text-xl">
                Enter the information provided to you
              </div>
            </section>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  for="email"
                  className="mb-2 block text-lg font-extrabold "
                >
                  Email
                </label>

                <input
                  type="email"
                  name="email"
                  className="w-full border border-gray-200 bg-white rounded-lg h-10 p-2"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div>
                <label
                  for="password"
                  className="mb-2 block text-lg font-extrabold "
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  className="w-full border border-gray-200 bg-white rounded-lg h-10 p-2"
                  placeholder="••••••••"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="bg-green-500 rounded-md text-white">
                <LoadingButton
                  loading={auth?.isFetching}
                  style={{ color: "white" }}
                  type="submit"
                  className="flex py-2 text-white items-center justify-center shadow-none w-full mx-auto text-xl"
                  onClick={() => handleLogin()}
                >
                  Sign in
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
