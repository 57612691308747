import { Popconfirm, Table } from "antd";
import { useEffect } from "react";
import "./msgTable.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMessageDatasAction,
  updateInfMsgAction,
} from "../../store/message/actions";
import DeleteIcon from "@mui/icons-material/Delete";

const MessageTable = () => {
  const { auth, messages } = useSelector((state) => state);
  const dispatch = useDispatch();

  const dataMapper = [];
  // const [sortedInfo, setSortedInfo] = useState({});
  // const handleChange = (sorter) => {
  //   setSortedInfo(sorter);
  // };

  useEffect(() => {
    getAllMessageDatasAction(auth.token, "?")(dispatch);
  }, [auth.token, dispatch]);

  messages?.all?.data?.forEach((el) => {
    dataMapper.push({
      key: el?._id,
      date: el?.date?.slice(0, 10),
      name: el?.name,
      email: el?.email,
      phone: el?.phone,
      nationality: el?.nationality,
      number: el?.number,
      message: el?.message,
      dispatch: dispatch,
      data: el,
    });
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Name/Group",
      dataIndex: "name",
      key: "name",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telephone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Nationality",
      dataIndex: "nationality",
      key: "nationality",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "Number of People",
      dataIndex: "number",
      key: "number",
    },
    Table.EXPAND_COLUMN,
    {
      title: "Messages",
      dataIndex: "message",
      key: "message",
      render: (text, _record) => <p className="capitalize">{text}</p>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      render: (text, record) => {
        const confirm = () => {
          updateInfMsgAction(record?.key, "", {
            isDelete: "true",
          })(record?.dispatch);
        };
        return (
          <Popconfirm
            title="About to remove :)"
            description="Are you sure to Remove this Booking?"
            okText="Yes"
            okButtonProps={{
              loading: messages?.isFetching,
              className: "bg-blue-500",
            }}
            onConfirm={confirm}
            cancelText="No"
          >
            <DeleteIcon style={{ color: "red" }} />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <div className="table-container">
      <Table
        columns={columns}
        dataSource={dataMapper}
        // onChange={handleChange}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.message}
            </p>
          ),
        }}
      />
    </div>
  );
};
export default MessageTable;
