import React from "react";
import DashboardLayout from "../layout/Layout";

const Exhibition = () => {
  return (
    <DashboardLayout>
      <div className="mt-6">Hello Exhibition</div>
    </DashboardLayout>
  );
};

export default Exhibition;
